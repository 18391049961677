import React from 'react';
import { motion } from 'framer-motion';
import { Button } from './ui/button';

export default function Hero() {
    return (
        <div className="relative h-screen flex items-center overflow-hidden bg-black font-noto-sans">
            {/* 背景画像（次世代形式対応） */}
            <picture className="absolute inset-0 z-0">
                <source src="/images/fashion-background.webp" type="image/webp" />
                <source src="/images/fashion-background.jpg" type="image/jpeg" />
                <img
                    src="/images/fashion-background.jpg"
                    alt="ファッションの未来をAIが創造"
                    className="w-full h-full object-cover"
                    loading="lazy"
                />
            </picture>

            {/* オーバーレイ */}
            <div className="absolute inset-0 bg-gradient-to-r from-black via-black/70 to-transparent z-10"></div>

            {/* コンテンツ */}
            <div className="relative z-20 container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
                    <motion.div
                        className="text-white"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold mb-6">
                            <span className="block">ファッションの未来を</span>
                            <span className="block mt-2">
                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">
                                    AIが創造する
                                </span>
                            </span>
                        </h1>
                        <motion.p
                            className="mt-3 text-lg text-gray-300 sm:text-xl max-w-md"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.2 }}
                        >
                            ピクルスで、アパレル撮影の常識を覆す。<br />
                            AIが実現する、効率的で創造的な新時代へ。
                        </motion.p>
                        <motion.div
                            className="mt-8 flex flex-col sm:flex-row gap-4"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.4 }}
                        >
                            <Button asChild size="lg" className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white">
                                <a href="#contact">
                                    無料相談を予約
                                </a>
                            </Button>
                            <Button asChild variant="outline" size="lg" className="w-full sm:w-auto bg-transparent text-white hover:bg-white hover:text-gray-900 border-white">
                                <a href="#features">
                                    詳細を見る
                                </a>
                            </Button>
                        </motion.div>
                    </motion.div>
                    <div className="hidden lg:block">
                        {/* 装飾やイラストを配置する場合 */}
                    </div>
                </div>
            </div>

            {/* 装飾的な要素 */}
            <motion.div
                className="absolute bottom-0 left-0 w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-20"
                animate={{
                    scale: [1, 1.2, 1],
                    rotate: [0, 90, 0],
                }}
                transition={{
                    duration: 8,
                    ease: "easeInOut",
                    repeat: Infinity,
                }}
            />
            <motion.div
                className="absolute top-1/4 right-0 w-72 h-72 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-20"
                animate={{
                    scale: [1, 1.2, 1],
                    rotate: [0, -90, 0],
                }}
                transition={{
                    duration: 10,
                    ease: "easeInOut",
                    repeat: Infinity,
                }}
            />
        </div>
    );
}