import React, { useState } from 'react';
import { motion } from 'framer-motion';

const examples = [
    {
        id: 1,
        title: 'カジュアルウェア',
        before: '/images/casual-before.jpg',
        after: '/images/casual-after.jpg',
    },
    {
        id: 2,
        title: 'フォーマルウェア',
        before: '/images/formal-before.jpg',
        after: '/images/formal-after.jpg',
    },
    {
        id: 3,
        title: 'スポーツウェア',
        before: '/images/sports-before.jpg',
        after: '/images/sports-after.jpg',
    },
];

const ExampleCard = ({ example }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <motion.div
            className="relative overflow-hidden rounded-lg shadow-lg"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="relative h-96">
                <img
                    src={example.before}
                    alt={`${example.title} - Before`}
                    className="absolute inset-0 w-full h-full object-cover transition-opacity duration-500"
                    style={{ opacity: isHovered ? 0 : 1 }}
                />
                <img
                    src={example.after}
                    alt={`${example.title} - After`}
                    className="absolute inset-0 w-full h-full object-cover transition-opacity duration-500"
                    style={{ opacity: isHovered ? 1 : 0 }}
                />
            </div>
            <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black via-black/70 to-transparent">
                <h3 className="text-xl font-bold text-white">{example.title}</h3>
                <p className="text-sm text-gray-300">
                    {isHovered ? 'After Piclus' : 'Before Piclus'}
                </p>
            </div>
            <div className="absolute top-4 left-4 bg-blue-600 text-white px-2 py-1 rounded">
                {isHovered ? 'After' : 'Before'}
            </div>
        </motion.div>
    );
};

export default function Examples() {
    return (
        <div className="bg-gray-900 py-16 sm:py-24" id="examples">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    className="text-center mb-12"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl mb-4">
                        使用例
                    </h2>
                    <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                        ピクルスを使用することで、どのように撮影プロセスが変わるのかをご覧ください。
                        画像にカーソルを合わせると、AIによる驚異的な変化をご確認いただけます。
                    </p>
                </motion.div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {examples.map((example) => (
                        <ExampleCard key={example.id} example={example} />
                    ))}
                </div>
            </div>
        </div>
    );
}

