import React from 'react';
import { motion } from 'framer-motion';
import { Clock, Users, Palette, RefreshCw, TrendingUp, ShieldCheck } from 'lucide-react';

// メリットデータ
const benefits = [
    {
        title: '撮影時間80%削減',
        description: 'AIによる自動化で、従来の撮影プロセスを大幅に短縮。効率的な製品画像作成を実現します。',
        icon: Clock,
    },
    {
        title: 'モデルコスト大幅節約',
        description: '実際のモデル起用頻度を減らし、人件費や関連経費を抑制。予算を効果的に活用できます。',
        icon: Users,
    },
    {
        title: '多様なモデル表現',
        description: '様々な体型や人種のモデルに簡単に適用。多様性を尊重した製品展示が可能になります。',
        icon: Palette,
    },
    {
        title: 'トレンド対応の柔軟性',
        description: '季節や最新流行に合わせて既存画像を瞬時に更新。常に鮮度の高い製品表現を維持できます。',
        icon: RefreshCw,
    },
    {
        title: 'リアルタイム更新',
        description: '在庫状況や需要変化に応じて、製品画像をリアルタイムで更新。常に最新の情報を提供します。',
        icon: TrendingUp,
    },
    {
        title: '販売機会の最大化',
        description: '需要予測に基づく効率的な在庫管理と販売戦略の立案。ビジネスリスクを軽減し、収益を向上させます。',
        icon: ShieldCheck,
    },
];

// メリットカードコンポーネント
const BenefitCard = ({ benefit, index }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="bg-gray-800 rounded-lg shadow-md overflow-hidden transform hover:shadow-lg transition-all duration-300 border border-gray-700"
        >
            <div className="p-6 flex items-start">
                <div className="bg-gray-700 rounded-full p-3 mr-4">
                    <benefit.icon className="h-6 w-6 text-blue-600" />
                </div>
                <div>
                    <h3 className="text-lg font-semibold text-white mb-2">
                        {benefit.title}
                    </h3>
                    <p className="text-gray-300 text-sm leading-relaxed">
                        {benefit.description}
                    </p>
                </div>
            </div>
        </motion.div>
    );
};

// メインのBenefitsコンポーネント
export default function Benefits() {
    return (
        <div className="py-20 bg-gray-900" id="benefits">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="text-center mb-16"
                >
                    <h2 className="text-3xl font-bold text-white sm:text-4xl mb-4">
                        ピクルスがもたらす
                        <span className="text-blue-600">革新的メリット</span>
                    </h2>
                    <p className="mt-4 text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
                        アパレル業界に革命を起こす、ピクルスの強力なソリューション。
                        ビジネスを変革し、競争力を高める具体的なメリットをご紹介します。
                    </p>
                </motion.div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {benefits.map((benefit, index) => (
                        <BenefitCard
                            key={benefit.title}
                            benefit={benefit}
                            index={index}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

