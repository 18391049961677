import React, { useState } from 'react';
import { motion } from 'framer-motion';

const faqs = [
    {
        question: "ピクルスのサービスは具体的にどのように行われますか？",
        answer: "お客様からのご要望に基づき、弊社のAI技術を用いて洋服の差し替えを行います。お客様自身がAIを操作する必要はなく、弊社のスタッフが代行して画像生成を行います。必要に応じて、専門のデザイナーがリテイクを行い、高品質な仕上がりを保証します。"
    },
    {
        question: "どのような種類の商品撮影に対応していますか？",
        answer: "幅広い商品カテゴリーに対応しています。衣服全般（トップス、ボトムス、ドレス、アウターなど）はもちろん、靴やアクセサリーなどの小物商品まで、様々な商品の魅力を最大限に引き出す画像制作が可能です。商品の特性に合わせて、最適な表現方法をご提案いたします。"
    },
    {
        question: "靴やアクセサリーなどの小物商品の撮影ではどのような工夫ができますか？",
        answer: "小物商品の魅力を引き立てるために、背景のデザインや色調の調整、ライティング効果の追加など、様々な画像処理技術を駆使します。商品の質感や細部を際立たせる高度な画像調整により、オンラインでも商品の魅力が十分に伝わるビジュアルを作成いたします。"
    },
    {
        question: "画像の品質はどの程度保証されますか？",
        answer: "弊社のAI技術は高解像度の画像処理に対応しており、元の画像品質を維持したまま洋服の差し替えや背景の変更を行います。さらに、必要に応じて専門のデザイナーが細部の調整を行うため、高品質な仕上がりを保証しています。"
    },
    {
        question: "サービスの利用にあたって、こちらで準備する必要があるものはありますか？",
        answer: "基本的には元となる画像と差し替えたい洋服の情報、または背景変更の要望をご提供いただくだけで結構です。詳細な要望やブランドガイドラインなどがあれば、それらも参考にさせていただきます。特別な機材や技術知識は必要ありません。"
    },
    {
        question: "納品までの流れと期間はどのようになっていますか？",
        answer: "ご依頼を受けてから、通常3〜5営業日で初回の画像をお届けします。その後、必要に応じてリテイクを行います。リテイクは1〜2回程度を想定しており、お客様のご満足いただける仕上がりになるまでサポートいたします。急ぎの案件についてもできる限り対応いたしますので、お気軽にご相談ください。"
    }
];

const FAQItem = ({ question, answer, isOpen, onClick }) => (
    <div className="border-b border-gray-200 py-4">
        <button
            className="flex justify-between items-center w-full text-left"
            onClick={onClick}
        >
            <span className="text-lg font-semibold text-gray-800">{question}</span>
            <span className="text-blue-500">{isOpen ? '−' : '+'}</span>
        </button>
        {isOpen && (
            <p className="mt-2 text-gray-600">{answer}</p>
        )}
    </div>
);

export default function FAQ() {
    const [openIndex, setOpenIndex] = useState(null);

    return (
        <section className="py-16 bg-gray-50" id="faq">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="text-center mb-12"
                >
                    <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl mb-4">
                        よくある質問
                    </h2>
                    <p className="text-xl text-gray-600">
                        ピクルスのサービスに関するよくある質問をまとめました。
                        さらに詳しい情報が必要な場合は、お気軽にお問い合わせください。
                    </p>
                </motion.div>

                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: index * 0.1 }}
                        >
                            <FAQItem
                                question={faq.question}
                                answer={faq.answer}
                                isOpen={openIndex === index}
                                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                            />
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
}

