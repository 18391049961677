import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Menu, X } from 'lucide-react';
import { Button } from "./ui/button";

const menuItems = [
    { name: '特徴', href: '#features' },
    { name: '使用例', href: '#examples' },
    { name: 'メリット', href: '#benefits' },
    { name: 'よくある質問', href: '#faq' },
    { name: 'お問い合わせ', href: '#contact' },
];

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();
    // Update 1: Changed isTermsPage to isLegalPage
    const isLegalPage = location.pathname === '/terms-of-service' || location.pathname === '/privacy-policy';
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 10);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Handle hash navigation
    useEffect(() => {
        // Check if there's a hash in the URL
        if (location.hash) {
            // Wait for the page to load
            setTimeout(() => {
                const element = document.querySelector(location.hash);
                if (element) {
                    // Get header height for offset
                    const header = document.querySelector('header');
                    const headerHeight = header ? header.offsetHeight : 0;

                    // Calculate position
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - headerHeight - 20; // 20px extra padding

                    // Smooth scroll to element
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }, 100);
        }
    }, [location]);

    const handleNavClick = (e, href) => {
        e.preventDefault();
        if (isLegalPage) {
            // 利用規約ページからメインページへの遷移
            navigate('/' + href);
        } else {
            // 同じページ内でのスクロール
            const element = document.querySelector(href);
            if (element) {
                const header = document.querySelector('header');
                const headerHeight = header ? header.offsetHeight : 0;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerHeight - 20;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
        setIsMenuOpen(false);
    };

    return (
        <header className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-white' : 'bg-transparent'}`}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <Link to="/" className="flex items-center">
                            <span className={`text-2xl font-bold font-['Orbitron'] tracking-wide bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text`}>
                                Piclus
                            </span>
                        </Link>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <button
                            type="button"
                            // Update 4: Changed isTermsPage to isLegalPage
                            className={`inline-flex items-center justify-center p-2 rounded-md ${isScrolled || isLegalPage ? 'text-gray-700 hover:text-gray-900' : 'text-gray-200 hover:text-white'} focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500`}
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                        >
                            <span className="sr-only">メニューを開く</span>
                            <Menu className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <nav className="hidden md:flex space-x-10">
                        {menuItems.map((item) => (
                            <a
                                key={item.name}
                                href={isLegalPage ? '/' + item.href : item.href}
                                onClick={(e) => handleNavClick(e, item.href)}
                                // Update 2: Changed isTermsPage to isLegalPage
                                className={`text-base font-medium ${isScrolled || isLegalPage
                                        ? 'text-gray-700 hover:text-gray-900'
                                        : 'text-gray-200 hover:text-white'
                                    }`}
                            >
                                {item.name}
                            </a>
                        ))}
                    </nav>
                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                        <Button asChild size="lg"
                            // Update 3: Changed isTermsPage to isLegalPage
                            className={`ml-8 ${isScrolled || isLegalPage ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-white hover:bg-gray-100 text-blue-600'}`}>
                            <a
                                href={isLegalPage ? '/#contact' : '#contact'}
                                onClick={(e) => handleNavClick(e, '#contact')}
                            >
                                資料請求
                            </a>
                        </Button>
                    </div>
                </div>
            </div>

            {/* モバイルメニュー */}
            <motion.div
                className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
            >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                    <div className="pt-5 pb-6 px-5">
                        <div className="flex items-center justify-between">
                            <div>
                                <span className="text-2xl font-bold font-['Orbitron'] tracking-wide bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text">
                                    Piclus
                                </span>
                            </div>
                            <div className="-mr-2">
                                <button
                                    type="button"
                                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    <span className="sr-only">メニューを閉じる</span>
                                    <X className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <div className="mt-6">
                            <nav className="grid gap-y-8">
                                {menuItems.map((item) => (
                                    <a
                                        key={item.name}
                                        href={isLegalPage ? '/' + item.href : item.href}
                                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                                        onClick={(e) => handleNavClick(e, item.href)}
                                    >
                                        <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                                    </a>
                                ))}
                            </nav>
                        </div>
                    </div>
                    <div className="py-6 px-5 space-y-6">
                        <Button asChild size="lg" className="w-full bg-blue-600 hover:bg-blue-700 text-white">
                            <a
                                href={isLegalPage ? '/#contact' : '#contact'}
                                onClick={(e) => handleNavClick(e, '#contact')}
                            >
                                資料請求
                            </a>
                        </Button>
                    </div>
                </div>
            </motion.div>
        </header>
    );
}

