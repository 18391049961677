import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Button } from "./ui/button";

export default function CTA() {
    return (
        <div
            className="relative bg-cover bg-center bg-no-repeat py-16 sm:py-24"
            style={{
                backgroundImage: `url('/images/design-background.jpg')`,
            }}
            id="cta"
        >
            {/* オーバーレイ */}
            <div className="absolute inset-0 bg-black/50 z-0"></div>

            {/* コンテンツ */}
            <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="text-center"
                >
                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl mb-4">
                        アパレルビジネスの未来を創造する準備はできていますか？
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-gray-300 max-w-3xl mx-auto">
                        ピクルスのAI技術が、あなたのビジネスにもたらす革新的な変化をぜひ体験してください。
                        詳細な情報をご希望の方は、ぜひ資料請求をお願いいたします。
                    </p>
                    <motion.div
                        className="mt-8 flex justify-center"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                    >
                        <Link to="#request-materials">
                            <Button size="lg" className="px-8 py-3 text-lg font-semibold bg-blue-600 hover:bg-blue-700 text-white">
                                資料請求
                            </Button>
                        </Link>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
}