import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StructuredData from './components/StructuredData';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Examples from './components/Examples';
import Benefits from './components/Benefits';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import CTA from './components/CTA';
import Footer from './components/Footer';

const TermsOfService = lazy(() => import('./components/TermsOfService'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));

function App() {
  return (
    <Router>
      <div className="App">
        <StructuredData
          name="Piclus"
          description="AIを活用したアパレル事業者向け洋服差し替えサービス。撮影効率化、コスト削減、多様な製品表現を実現します。"
          url="https://piclus.jp"
        />
        <Header />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={
              <>
                <Hero />
                <Features />
                <Examples />
                <CTA />
                <Benefits />
                <FAQ />
                <Contact />
              </>
            } />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

