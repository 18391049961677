import React from 'react';
import { motion } from 'framer-motion';
import { Camera, Clock, DollarSign } from 'lucide-react';

const features = [
    {
        name: 'AI洋服差し替え',
        description: '高度なAI技術により、モデル画像の洋服を自動的に差し替えます。リアルな仕上がりで、違和感のない製品画像を生成します。',
        icon: Camera,
        image: '/images/ai-clothing-replacement.jpg',
    },
    {
        name: '時間の大幅削減',
        description: 'モデルの手配や衣装の準備、撮影セッションにかかる時間を削減。効率的な製品画像の作成が可能になります。',
        icon: Clock,
        image: '/images/time-saving.jpg',
    },
    {
        name: 'コスト削減',
        description: 'モデル起用費、スタジオレンタル費、撮影機材のコストを大幅に削減。経費節減に貢献します。',
        icon: DollarSign,
        image: '/images/cost-reduction.jpg',
    },
];

const FeatureCard = ({ feature, index }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            className="bg-white rounded-lg shadow-xl overflow-hidden transform hover:scale-105 transition-all duration-300"
        >
            <div className="relative h-64 overflow-hidden">
                <img
                    src={feature.image}
                    alt={feature.name}
                    className="w-full h-full object-cover transition-transform duration-300 transform hover:scale-110"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black via-black/60 to-transparent opacity-70"></div>
                <div className="absolute bottom-4 left-4 text-white">
                    <feature.icon className="h-8 w-8 mb-2" />
                    <h3 className="text-xl font-bold">{feature.name}</h3>
                </div>
            </div>
            <div className="p-6">
                <p className="text-gray-600">{feature.description}</p>
            </div>
        </motion.div>
    );
};

export default function Features() {
    return (
        <div className="py-16 bg-gradient-to-b from-gray-100 to-white" id="features">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="text-center mb-12"
                >
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        特徴
                    </h2>
                    <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
                        ピクルスが提供する革新的な機能
                    </p>
                    <p className="mt-2 text-lg text-gray-500 max-w-3xl mx-auto">
                        アパレル業界の撮影ワークフローを一新する、最先端のAI技術をご紹介します。
                    </p>
                </motion.div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {features.map((feature, index) => (
                        <FeatureCard key={feature.name} feature={feature} index={index} />
                    ))}
                </div>
            </div>
        </div>
    );
}

