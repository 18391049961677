import React, { useState } from 'react';
import { motion } from 'framer-motion';

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        interest: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');
    const [isError, setIsError] = useState(false); // エラー状態を管理

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitMessage(''); // メッセージをリセット
        setIsError(false); // エラー状態をリセット
        try {
            const response = await fetch('/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const data = await response.json();
            setSubmitMessage('お問い合わせを受け付けました。資料をメールでお送りしました。'); // 成功メッセージを設定
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            setSubmitMessage(
                'エラーが発生しました。sales@piclus.jpにご連絡ください。'
            ); // エラーメッセージを設定
            setIsError(true);
        }
        setIsSubmitting(false);
    };

    return (
        <section className="py-16 bg-gray-900" id="contact">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="text-center mb-12"
                >
                    <h2 className="text-3xl font-bold text-white sm:text-4xl mb-4">
                        資料請求
                    </h2>
                    <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                        ピクルスの革新的なAI技術がもたらす、アパレル業界の未来をより詳しく知りたい方は
                        ぜひ資料をご請求ください。専門スタッフが丁寧にご説明いたします。
                    </p>
                </motion.div>

                <motion.form
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    onSubmit={handleSubmit}
                    className="bg-white p-8 rounded-lg shadow-xl space-y-6"
                >
                    {/* フォーム入力フィールド */}
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            お名前 *
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                            会社名
                        </label>
                        <input
                            type="text"
                            id="company"
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            メールアドレス *
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            電話番号
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="interest" className="block text-sm font-medium text-gray-700">
                            興味のある項目
                        </label>
                        <select
                            id="interest"
                            name="interest"
                            value={formData.interest}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        >
                            <option value="">選択してください</option>
                            <option value="AI技術の詳細">AI技術の詳細</option>
                            <option value="導入事例">導入事例</option>
                            <option value="料金プラン">料金プラン</option>
                            <option value="導入ステップ">導入ステップ</option>
                            <option value="その他">その他</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                            その他ご要望
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            value={formData.message}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        ></textarea>
                    </div>
                    <div>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            {isSubmitting ? '送信中...' : '資料を請求する'}
                        </button>
                    </div>
                    {/* メッセージ表示 */}
                    {submitMessage && (
                        <p
                            className={`text-center mt-4 ${isError ? 'text-red-600' : 'text-green-600'
                                }`}
                        >
                            {submitMessage}
                        </p>
                    )}
                </motion.form>
            </div>
        </section>
    );
}